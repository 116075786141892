import { Injectable } from '@angular/core';
import {
  Entity,
  EntityKey,
  EntityQuery,
  EntityState,
  EntityType,
  MetadataStore,
  QueryResult,
  SaveResult
} from 'breeze-client';
import { IEntityByKeyResult } from 'breeze-client/src/entity-manager';
import { QueryOptionsConfig } from 'breeze-client/src/query-options';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { distinctUntilChanged, filter, map, pluck, shareReplay, startWith } from 'rxjs/operators';
import { FetchSuccessNotification, ImportNotification, SaveSuccessNotification } from './breeze-notification';
import { DefaultEntity } from './default-entity';
import { EntityId, getParseEntityKeyValuesFunc, getParseIdFunc } from './entity-id-functions';
import { GraphLoadPurpose } from './queries';
import { UnitOfWorkService } from './unit-of-work.service';

type IdSelectorStr<T> = (model: T) => string;
type IdSelectorNum<T> = (model: T) => number;
type IdSelector<T> = IdSelectorStr<T> | IdSelectorNum<T>;

const getSelectIdFunc = ({ keyProperties }: EntityType): IdSelector<Entity> => {
  if (keyProperties.length === 1) {
    return (e: Entity) => e.entityAspect.getKey().values[0];
  } else {
    return (e: Entity) => e.entityAspect.getKey().values.join('~');
  }
};

export interface CreateEntityOptions {
  /*
   * Attach the newly created entity to the current entityManager
   * (default: `true`)
   * */
  attach?: boolean;
}

const defaultCreateEntityOptions: Required<CreateEntityOptions> = {
  attach: true
};

export interface FetchEntityOptions<T> {
  /*
   * First try and find the entity in the local cache. Only if there is a cache miss, to query the server.
   * (default: `false`)
   * */
  checkLocalCacheFirst?: boolean;
  /**
   * The navigation paths to expand when fetching the entity from the server.
   * IMPORTANT: if the entity already existing locally and `checkLocalCacheFirst` is `true` a
   * further query to the server will NOT be performed, and therefore the navigation properties might
   * not have been loaded
   * */
  expands?: Array<keyof T>;
  /**
   * Override of the default options used when executing the query against the server
   * */
  queryOptions?: QueryOptionsConfig;
}

export interface SetDeletedOptions {
  /**
   * By default, only where the entity(s) being deleted defines one or more navigation property paths that require
   * fetching for a delete operation, will the latest state of the entity be fetched.
   * Supply `true` to always cause the latest state for the entity(s) to be fetched
   * */
  forceReload?: boolean;
}

function queryResultsHasEntityOfType(entityType: EntityType) {
  return ({ retrievedEntities, results }: QueryResult) =>
    (retrievedEntities ?? results).filter(
      (e: Entity) =>
        e.entityType === entityType ||
        (e.entityType?.baseEntityType !== undefined ? e.entityType.baseEntityType.name === entityType.name : false)
    ).length > 0;
}

const isBusy = (opCount: number) => opCount > 0;
const isAnyBusy = ({ creatingCount, deletingCount }: RepositoryState) => [creatingCount, deletingCount].some(isBusy);

export interface RepositoryState {
  creatingCount: number;
  deletingCount: number;
}

const initialState: RepositoryState = {
  creatingCount: 0,
  deletingCount: 0
};

type PickSlice<T extends object, K extends keyof T> = Pick<T, { [I in K]: I }[K]>;

@Injectable()
export class RepositoryService<T extends Entity = Entity> {
  entityType: EntityType;

  entities$: Observable<T[]>;
  selectId: ReturnType<typeof getSelectIdFunc>;
  parseId: ReturnType<typeof getParseIdFunc>;
  parseKeyValues: ReturnType<typeof getParseEntityKeyValuesFunc>;
  private repositoryState = new BehaviorSubject<RepositoryState>(initialState);
  isCreating$ = this.selectState('creatingCount').pipe(map(isBusy));
  isDeleting$ = this.selectState('deletingCount').pipe(map(isBusy));
  isBusy$ = this.createIsBusy$();

  get isBusy() {
    return isAnyBusy(this.repositoryState.value);
  }

  get isCreating() {
    return isBusy(this.repositoryState.value.creatingCount);
  }

  get isDeleting() {
    return isBusy(this.repositoryState.value.deletingCount);
  }

  /**
   * use the *same* array to allow observable chain to de-dup multiple emissions
   * that result in zero matches
   */
  private readonly noMatch = Object.freeze(new Array<T>()) as T[];

  constructor(
    public uow: UnitOfWorkService,
    private metadataStore: MetadataStore,
    public baseQuery: EntityQuery
  ) {
    this.uow.registerRepository(this, new.target);
    const entityTypeName = metadataStore.getEntityTypeNameForResourceName(this.baseQuery.resourceName ?? '');
    this.entityType = metadataStore.getAsEntityType(entityTypeName ?? 'Unknown');
    this.selectId = getSelectIdFunc(this.entityType);
    this.parseId = getParseIdFunc(this.entityType);
    this.parseKeyValues = getParseEntityKeyValuesFunc(this.entityType);
    this.entities$ = this.createEntities$();

    // make it convenient to pass methods as function pointers
    this.all = this.all.bind(this);
    this.createEntity = this.createEntity.bind(this);
    this.entitiesByQuery$ = this.entitiesByQuery$.bind(this);
    this.ensureGetLocalEntityByKey = this.ensureGetLocalEntityByKey.bind(this);
    this.executeQueryLocally = this.executeQueryLocally.bind(this);
    this.executeQuery = this.executeQuery.bind(this);
    this.getLocalEntities = this.getLocalEntities.bind(this);
    this.getLocalEntityByKey = this.getLocalEntityByKey.bind(this);
    this.withId = this.withId.bind(this);
    this.projectWithId = this.projectWithId.bind(this);
  }

  get manager() {
    return this.uow.manager;
  }

  async all(queryOptions?: QueryOptionsConfig): Promise<T[]> {
    return await this.executeQuery(this.baseQuery.using(this.getQueryOptions(queryOptions)));
  }

  async createEntity(initialValues?: Partial<T>, options?: CreateEntityOptions): Promise<T> {
    this.patchState(({ creatingCount }) => ({ creatingCount: creatingCount + 1 }));
    try {
      return this.doCreateEntity(initialValues, options);
    } finally {
      this.patchState(({ creatingCount }) => ({ creatingCount: creatingCount - 1 }));
    }
  }

  entitiesByQuery$(query: EntityQuery): Observable<T[]> {
    return this.entities$.pipe(
      map(() => {
        const matches = this.executeQueryLocally(query);
        return matches.length > 0 ? matches : this.noMatch;
      }),
      distinctUntilChanged(),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  protected doCreateEntity(initialValues?: Partial<T>, options?: CreateEntityOptions): Promise<T> {
    const newEntity = this.createInitialEntity(initialValues, options);
    return Promise.resolve(newEntity);
  }

  createInitialEntity(initialValues?: Partial<T>, options?: CreateEntityOptions) {
    const { attach } = { ...defaultCreateEntityOptions, ...options };
    const newEntity: T = attach
      ? this.manager.createEntity(this.entityType, initialValues)
      : this.entityType.createEntity(initialValues);
    return newEntity;
  }

  cloneEntity(entity: T, initialValues: Partial<T>): Promise<T> {
    const rawValues = this.manager.helper.unwrapInstance(entity);
    let newRawValues = {};
    Object.keys(rawValues).forEach(key => {
      const newPair = { [key[0].toLowerCase() + key.slice(1)]: rawValues[key] };
      newRawValues = { ...newRawValues, ...newPair };
    });
    return this.createEntity({ ...newRawValues, ...initialValues });
  }

  ensureGetLocalEntity(entity: Entity): T {
    const localEntity = this.getLocalEntityByKey(entity.entityAspect.getKey().values);
    if (!localEntity) {
      throw new Error('Entity not found in entity cache');
    }
    return localEntity;
  }

  ensureGetLocalEntityByKey(keyValues: EntityId | EntityId[]): T {
    const entity = this.getLocalEntityByKey(keyValues);
    if (!entity) {
      throw new Error('Entity not found in entity cache');
    }
    return entity;
  }

  executeQueryLocally(query: EntityQuery): T[] {
    this.assertQueriesEntityType(query);
    return this.manager.executeQueryLocally(query);
  }

  async executeQuery(query: EntityQuery): Promise<T[]> {
    this.assertQueriesEntityType(query);
    const data = await this.manager.executeQuery(query);
    return data.results as T[];
  }

  getLocalEntities(entityStates?: EntityState | EntityState[]): T[] {
    return this.manager.getEntities(this.entityType, entityStates) as T[];
  }

  getLocalEntityByKey(keyValues: EntityId | EntityId[]): T | null {
    if (typeof keyValues === 'string') {
      keyValues = this.parseKeyValues(keyValues);
    }
    return this.manager.getEntityByKey(this.entityType, keyValues) as T;
  }

  hasLocalEntities() {
    return this.getLocalEntities().length > 0;
  }

  setDeleted(entity: T, options?: SetDeletedOptions): Promise<void>;
  setDeleted(entity: T[], options?: SetDeletedOptions): Promise<void>;
  async setDeleted(entityOrList: T | T[], options?: SetDeletedOptions): Promise<void> {
    const entities = Array.isArray(entityOrList) ? entityOrList : [entityOrList];
    if (entities.length === 0) return;

    const list = entities.filter(e => e instanceof DefaultEntity) as unknown as DefaultEntity[];

    if (list.length !== entities.length) {
      throw new Error(`instances of ${DefaultEntity.name} expected`);
    }

    const { forceReload } = options ?? {};

    const existing = list.filter(e => !e.entityAspect.entityState.isAdded());
    if (existing.length > 0 && (forceReload || existing[0].getPropertyPathsFor(GraphLoadPurpose.Delete).length > 0)) {
      const query = existing[0].getLoadGraphsQuery(GraphLoadPurpose.Delete);
      const requiringLoad = !forceReload ? existing.filter(e => !e.isGraphLoaded(GraphLoadPurpose.Delete)) : existing;
      const fetch = async () => {
        await query(requiringLoad);
      };
      await this.executeFetchForDelete(fetch);
    }
    list.forEach(e => {
      e.setDeleted();
    });
  }

  async withId(keyValues: EntityId | EntityId[], options?: FetchEntityOptions<T>): Promise<T | undefined> {
    if (typeof keyValues === 'string') {
      keyValues = this.parseKeyValues(keyValues);
    }
    const { checkLocalCacheFirst, expands, queryOptions } = { checkLocalCacheFirst: false, ...options };
    const qo = this.getQueryOptions(queryOptions);

    const key = new EntityKey(this.entityType, keyValues);
    if (expands && expands.length > 0) {
      const localEntity = this.uow.manager.getEntityByKey(key);
      if (localEntity && checkLocalCacheFirst) {
        return Promise.resolve(localEntity as T);
      }
      const eq = EntityQuery.fromEntityKey(key)
        .expand(expands as string[])
        .using(qo);
      const entities = await this.executeQuery(eq);
      return entities[0];
    }

    // `fetchEntityByKey` does not allow us to supply query options, so we're temporarily overriding our
    // `manager.queryOptions` which will be used internally by `fetchEntityByKey`
    const originalQueryOptions = this.manager.queryOptions;
    this.manager.queryOptions = qo;
    let futureResult: Promise<IEntityByKeyResult>;
    try {
      futureResult = this.uow.manager.fetchEntityByKey(key, checkLocalCacheFirst);
    } finally {
      this.manager.queryOptions = originalQueryOptions;
    }
    const result = await futureResult;
    return result.entity as T | undefined;
  }

  async projectWithId<K extends keyof T>(
    keyValues: EntityId | EntityId[],
    properties: K[]
  ): Promise<PickSlice<T, K> | undefined> {
    const key = new EntityKey(this.entityType, keyValues);
    // bug: Cloudflare blocks projection requests made by breeze so need to handle projections once the entity has been
    // returned from the server as a work around.
    // TODO: Add projections directly to the query after the cloudflare error is fixed
    // const eq = EntityQuery.fromEntityKey(key).noTracking().select(properties as string[])
    const eq = EntityQuery.fromEntityKey(key).noTracking();
    const entities = await this.executeQuery(eq);
    const match = entities[0];
    if (!match) {
      return undefined;
    }

    // bug: EntityQuery.fromEntityKey returns a partially populated entity rather than the actual projection from the server
    // projecting the entity ourselves is to work around this problem
    return Object.entries(match)
      .filter(([key]) => properties.includes(key as K))
      .reduce((acc, [key, value]) => Object.assign(acc, { [key]: value }), {} as PickSlice<T, K>);
  }

  private assertQueriesEntityType(query: EntityQuery) {
    const queriedEntity = this.metadataStore.getEntityTypeNameForResourceName(query.resourceName ?? '');
    if (queriedEntity !== this.entityType.name) {
      throw new Error('The query supplied does not match the entity type for this repository');
    }
  }

  saveResultsHasEntityOfType(entityType: EntityType) {
    return ({ entities, deletedKeys }: Pick<SaveResult, 'entities' | 'deletedKeys'>) =>
      entities.some(
        e =>
          e.entityType === entityType ||
          (e.entityType.baseEntityType !== undefined ? e.entityType.baseEntityType.name === entityType.name : false)
      ) ||
      (deletedKeys != undefined && deletedKeys.some(key => key.entityTypeName === entityType.name));
  }

  private createEntities$() {
    const imports$ = this.uow.notificationsOfType$(ImportNotification);

    const hasMyEntities = queryResultsHasEntityOfType(this.entityType);
    const fetches$ = this.uow
      .notificationsOfType$(FetchSuccessNotification)
      .pipe(filter(qr => hasMyEntities(qr.result)));

    const hasMySavedEntities = this.saveResultsHasEntityOfType(this.entityType);
    const saves$ = this.uow.notificationsOfType$(SaveSuccessNotification).pipe(filter(sr => hasMySavedEntities(sr)));

    return merge(imports$, fetches$, saves$).pipe(
      startWith(true),
      map(() => this.manager.executeQueryLocally(this.baseQuery)),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  private createIsBusy$() {
    return this.repositoryState.pipe(
      map(isAnyBusy),
      distinctUntilChanged(),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  private async executeFetchForDelete(fetch: () => Promise<void>) {
    this.patchState(({ deletingCount }) => ({ deletingCount: deletingCount + 1 }));
    try {
      await fetch();
    } finally {
      this.patchState(({ deletingCount }) => ({ deletingCount: deletingCount - 1 }));
    }
  }

  private getQueryOptions(overrides?: QueryOptionsConfig) {
    return overrides ? this.manager.queryOptions.using(overrides) : this.manager.queryOptions;
  }

  private patchState(patch: (state: RepositoryState) => Partial<RepositoryState>) {
    const existingState = this.repositoryState.value;
    const newState = patch(existingState);
    this.repositoryState.next({ ...existingState, ...newState });
  }

  private selectState<T extends keyof RepositoryState>(stateKey: T) {
    return this.repositoryState.pipe(
      pluck(stateKey),
      distinctUntilChanged(),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }
}
