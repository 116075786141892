import { BaseEntity } from '../base-entity';
import { Bookmark } from './bookmark';
import { IdentityPrincipal } from './identity-principal';
import { OrganisationWorkspace } from './organisation-workspace';
import { PbiReport } from './pbi-report';
import { ReportAccess } from './report-access';
import { ReportActivityRecord } from './report-activity-record';
import { ReportUserPreference } from './report-user-preference';

/// <code-import> Place custom imports between <code-import> tags
import { EntityName } from '@mri-platform/dsg/core';
import { AuthzContext, AuthzContextsActionMap, CrudAction, CrudAllAction } from '@mri-platform/resource-authz';
import { distinctValues } from '@mri-platform/shared/core';
import { MetadataStore, breeze } from 'breeze-client';
import { TemplateWorkspaceIcon } from './template-workspace-icon';
/// </code-import>

export interface Report {
  id: string;
  createdDate: Date;
  description?: string;
  embedUrl: string;
  hasStandardRlsRoles: boolean;
  hidesReportId?: string;
  isCustom: boolean;
  isDefault: boolean;
  isDeleted: boolean;
  isDetached: boolean;
  isPublished: boolean;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  latestProblem?: string;
  name: string;
  organisationWorkspaceId: string;
  ownerId: string;
  parentReportId?: string;
  periodEnd: Date;
  periodStart: Date;
  templateDatasetName?: string;
  templateReportName?: string;
  tenantKey: string;
  version: number;
  webUrl: string;
  activityRecords: ReportActivityRecord[];
  allowedPrincipals: ReportAccess[];
  bookmarks: Bookmark[];
  hidesReport: Report;
  organisationWorkspace: OrganisationWorkspace;
  owner: IdentityPrincipal;
  parentReport: Report;
  pbiReports: PbiReport[];
  userPreferences: ReportUserPreference[];
}

/// <module-code> Place module level code between <module-code> tags
export enum ReportCustomAction {
  Export = 'Export'
}

export type ReportIcon = {
  id: string;
  organisationWorkspace_TemplateWorkspace_Icon: TemplateWorkspaceIcon;
};
/// </module-code>

export class Report extends BaseEntity {
  /// <code> Place custom code between <code> tags
  static authorization = {
    ...AuthzContextsActionMap.crudFor(EntityName.Report),
    [ReportCustomAction.Export]: {
      action: ReportCustomAction.Export,
      resource: EntityName.Report
    },
    /* Note: The Report will always have a PbiReport on the server-side,
    hence if a report is deleted than corresponding pbiReports get deleted too.*/
    [CrudAction.Delete]: AuthzContext.updateFor(
      BaseEntity.qualifiedPropName<Report>(EntityName.Report, 'isDeleted'),
      BaseEntity.qualifiedPropName<PbiReport>(EntityName.PbiReport, 'isDeleted')
    ),
    [CrudAllAction.Delete]: AuthzContext.updateAllFor(
      BaseEntity.qualifiedPropName<Report>(EntityName.Report, 'isDeleted'),
      BaseEntity.qualifiedPropName<PbiReport>(EntityName.PbiReport, 'isDeleted')
    )
  };

  static propName(name: keyof Report) {
    return name;
  }

  static register(metadataStore: MetadataStore) {
    const et = metadataStore.getAsEntityType(EntityName.Report, false);
    //NOTE:Ensure the Regex is maintained in sync with the server-side, which can be found in Report.cs file.
    // see link https://github.com/MRI-Software/data-services-gateway/blob/master/src/DataServicesGateway.Shared/Model/Report.cs for more information
    const validNameRegEx = breeze.Validator.makeRegExpValidator(
      'validName',
      /^(?!.*[[\]]).*$/,
      "'%displayName%' cannot contain '[' or ']'"
    );
    //Register the validator with breeze
    breeze.Validator.register(validNameRegEx);

    const prop = et.getProperty(Report.propName('name'));
    prop.validators.push(validNameRegEx);
  }

  static hasMultipleWorkspaces = (reports: Report[]) => distinctValues(reports, 'organisationWorkspaceId').length > 1;
  /// </code>
  // Generated code. Do not place code below this line.
}
