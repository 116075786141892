import { Inject, Injectable, Optional } from '@angular/core';
import { MetadataStore } from 'breeze-client';
import { BREEZE_ENTITY_REGISTRATION_FUNC, BREEZE_METADATA_TOKEN, BreezeEntityRegistryFunc } from './injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class MetadataStoreFactoryService {
  private registrationFuncs: BreezeEntityRegistryFunc[];
  private readonly metadata: Object;

  constructor(
    @Inject(BREEZE_ENTITY_REGISTRATION_FUNC) registrationFuncs: BreezeEntityRegistryFunc[],
    @Optional() @Inject(BREEZE_METADATA_TOKEN) metadata?: Object
  ) {
    this.metadata = metadata ?? {};
    this.registrationFuncs = registrationFuncs;
  }

  create() {
    const metadataStore = new MetadataStore();
    metadataStore.importMetadata(this.metadata);
    this.registrationFuncs.forEach(register => register(metadataStore));
    return metadataStore;
  }
}
