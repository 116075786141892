import { Directive, ElementRef, HostBinding, HostListener, Input, OnDestroy, Renderer2 } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { isNotNullOrUndefined } from '@mri-platform/shared/core';
import { ChangeNotificationService, GridComponent } from '@progress/kendo-angular-grid';
import { DataResult } from '@progress/kendo-data-query';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { GridDataResult } from '../functions/process-grid-data';

const isFilteredGridDataResult = (value: unknown[] | DataResult): value is GridDataResult =>
  value != null && 'filteredData' in value && 'total' in value;
const isDataResult = (value: unknown[] | DataResult | null): value is DataResult =>
  value != null && 'data' in value && 'total' in value;

@Directive({ selector: '[mriSharedEditableColumnSelectAll]' })
export class EditableColumnSelectAllDirective implements OnDestroy {
  @Input('mriSharedEditableColumnSelectAll') columnName = '';

  @HostBinding('attr.type') type = 'checkbox';

  private checkedChanges = new Subject<boolean>();
  private gridData$: Observable<FormGroup[]>;
  private subscription = new Subscription();

  @HostListener('click') onClick() {
    this.checkedChanges.next(this.checkboxInput.nativeElement.checked);
  }

  constructor(
    gridChanges: ChangeNotificationService,
    grid: GridComponent,
    private checkboxInput: ElementRef<HTMLInputElement>,
    private renderer: Renderer2
  ) {
    this.gridData$ = this.createGridData$(gridChanges, grid);

    let sub = this.checkedChanges.pipe(withLatestFrom(this.gridData$)).subscribe(([checked, data]) => {
      this.selectAll(checked, data);
    });
    this.subscription.add(sub);

    sub = this.createCheckBoxChecked$().subscribe(checked => {
      this.setCheckBoxState(checked);
    });
    this.subscription.add(sub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private assertFormArrayFormGroupItems() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (source$: Observable<any[]>): Observable<FormGroup[]> =>
      source$.pipe(
        map(data => {
          if (data.length === 0) {
            return data;
          }

          const baseErrorMsg =
            'EditableColumnSelectAllDirective expects grid data to be a collection of FormGroup instances';

          const [item] = data;
          if (!(item instanceof FormGroup)) {
            throw new Error(baseErrorMsg);
          }
          if (item.parent == null || !(item.parent instanceof FormArray)) {
            throw new Error(`${baseErrorMsg} whose parent field is an instance of a FormArray`);
          }

          return data;
        })
      );
  }

  private createCheckBoxChecked$() {
    const gridDataRowChanges$ = this.gridData$.pipe(
      filter(rows => rows.length > 0),
      map(rows => rows[0].parent),
      isNotNullOrUndefined(),
      distinctUntilChanged(),
      switchMap(formArray => formArray.valueChanges),
      withLatestFrom(this.gridData$, (_, data) => data)
    );

    const rowsValue$ = merge(gridDataRowChanges$, this.gridData$);

    const columnValue$ = rowsValue$.pipe(map(rows => rows.map(row => row.get(this.columnName)?.value)));

    return columnValue$.pipe(
      map(values => {
        if (values.length > 0 && values.every(v => v === true)) {
          return true;
        } else if (values.some(v => v === true)) {
          return undefined;
        } else {
          return false;
        }
      })
    );
  }

  private createGridData$(gridChanges: ChangeNotificationService, grid: GridComponent) {
    return gridChanges.changes.pipe(
      map(() => {
        if (grid.data == null) return [];
        if (isFilteredGridDataResult(grid.data)) {
          return grid.data.filteredData;
        } else {
          return isDataResult(grid.data) ? grid.data.data : grid.data;
        }
      }),
      this.assertFormArrayFormGroupItems(),
      startWith([]),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  private selectAll(checked: boolean, rows: FormGroup[]) {
    if (rows.length === 0) {
      return;
    }

    rows.forEach(row => {
      const cell = row.get(this.columnName);
      if (cell instanceof AbstractControl) {
        // we're not emitting valueChanges event as a workaround to not being able to STOP
        // receiving all but the last of these emissions ourself;
        // we don't want to receive these emissions ourselves because there could potentially be
        // hundreds / thousands / "lots" and this would be a problem for perf
        cell.setValue(checked, { emitEvent: false });
        cell.markAsDirty();
        cell.updateValueAndValidity({ emitEvent: false });
      }
    });

    // this is a contination of the workaround above...
    // because we are supressing the individual valueChanges above, we are at least
    // emitting an event for the parent FormArray;
    rows[0].parent?.updateValueAndValidity();
  }

  private setCheckBoxState(checked: boolean | undefined) {
    const elem = this.checkboxInput.nativeElement;
    this.renderer.setProperty(elem, 'indeterminate', checked === undefined);
    this.renderer.setProperty(elem, 'checked', checked === true);
  }
}
