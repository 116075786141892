import { Entity, EntityManager, EntityState } from 'breeze-client';
import { ImportConfig } from 'breeze-client/src/entity-manager';
import partition from 'lodash-es/partition';
import { isEntity, isEntityArray } from './type-guard-functions';

const exportForCopyOptions = { includeMetadata: false, asString: false };

export interface CopyEntitiesConfig extends ImportConfig {
  /**
   * Should instances that are detached from the `source` be merged into the `target`.
   * Implicitly set to `true` whenever `mergeDetachedAs` is assigned to a value
   * @default false
   * */
  mergeDetached?: boolean;

  /**
   * When `mergeDetached` is true, determines the `EntityState` that detached instances from `source`
   * should have when attached to `target`.
   * @Default `EntityState.Added`
   * */
  mergeDetachedAs?: EntityState;
}

export function copyEntities<T extends Entity>(
  source: EntityManager,
  target: EntityManager,
  entityOrEntities: T[] | T,
  copyOptions?: CopyEntitiesConfig
) {
  const list = Array.isArray(entityOrEntities) ? entityOrEntities : [entityOrEntities];
  const [detached, entitiesToExport] = partition(list, e => e.entityAspect.entityState.isDetached());
  const exported = source.exportEntities(entitiesToExport, exportForCopyOptions);

  const { mergeDetached, mergeDetachedAs, ...importOptions } = copyOptions ?? {};

  if (mergeDetached || mergeDetachedAs) {
    if (mergeDetachedAs === EntityState.Detached) {
      detached.forEach(detached => {
        const entity = target.getEntityByKey(detached.entityAspect.getKey());
        entity && entity.entityAspect.setDetached();
      });
    } else {
      detached.forEach(e => {
        target.attachEntity(e, mergeDetachedAs ?? EntityState.Added);
      });
    }
  }

  const { entities } = target.importEntities(exported, importOptions);
  //NOTE: the client-side errors are copied implicitly.
  copyServerValidationErrors(entitiesToExport, entities);
  return Array.isArray(entityOrEntities) ? (entities.concat(detached) as T[]) : ((entities[0] ?? detached[0]) as T);
}

export function copyEntityMap<T extends Record<keyof T, Entity | Entity[]>>(
  source: EntityManager,
  target: EntityManager,
  entityMap: T,
  copyOptions?: CopyEntitiesConfig
): T {
  const sandboxEntries = Object.entries<Entity | Entity[]>(entityMap).filter(
    ([, value]) => isEntity(value) || isEntityArray(value)
  );
  return sandboxEntries.reduce(
    (result, [key, value]) => Object.assign(result, { [key]: copyEntities(source, target, value, copyOptions) }),
    {} as T
  );
}

export function copyServerValidationErrors(source: Entity[], target: Entity[]) {
  source.forEach((entity, i) => {
    const targetEntity = target[i];
    const targetErrors = targetEntity.entityAspect.getValidationErrors().filter(err => err.isServerError);
    targetErrors.forEach(error => targetEntity.entityAspect.removeValidationError(error));

    const sourceErrors = entity.entityAspect.getValidationErrors().filter(error => error.isServerError);
    sourceErrors.forEach(error => targetEntity.entityAspect.addValidationError(error));
  });
}
