import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SanitizedError } from '@mri-platform/angular-error-handling';
import { ApiConfig } from '@mri-platform/shared/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class ApiVersionInterceptor implements HttpInterceptor {
  static apiVersionHeaderName = 'X-Mri-Api-Version';

  constructor(private apiConfig: ApiConfig) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const shouldInspectResponse = req.url.startsWith(this.apiConfig.url);
    if (!shouldInspectResponse) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      tap(resp => this.setSanitizedErrorApiVs(resp)),
      catchError(err => {
        this.setSanitizedErrorApiVs(err);
        return throwError(err);
      })
    );
  }

  private setSanitizedErrorApiVs(response: unknown) {
    if (!(response instanceof HttpResponse || response instanceof HttpErrorResponse)) return;

    const apiVersion = response.headers.get(ApiVersionInterceptor.apiVersionHeaderName);
    if (apiVersion) {
      SanitizedError.setVersionInfo({ apiVersion });
    }
  }
}
