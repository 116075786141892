import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENRICH_HTTP_ERROR } from '@mri-platform/angular-error-handling';
import { ApiConfig } from '@mri-platform/shared/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ClientSwitchPageService {
  baseUrl: string;

  constructor(apiConfig: ApiConfig, private http: HttpClient) {
    this.baseUrl = apiConfig.url;
  }

  validateClientId(id: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/organisations/${id}/validate`, {
      context: new HttpContext().set(ENRICH_HTTP_ERROR, true)
    });
  }
}
