import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutes } from '@mri-platform/dsg/core';
import { OrganisationDtoResolver } from '@mri-platform/dsg/entity-state';
import { SharedCommonUiModule } from '@mri-platform/shared/common-ui';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ClientSwitchPageComponent } from './client-switch-page/client-switch-page.component';

const KENDO_MODULES = [ButtonsModule, DropDownsModule, InputsModule];

export const routes: Routes = [
  AppRoutes.SwitchClient.applyTo({
    path: 'switch',
    component: ClientSwitchPageComponent,
    resolve: {
      // we need to ensure the breeze EntityManager does NOT track these instances of OrganisationPrincipal
      // because all other parts of our app should ONLY work with the one OrganisationPrincipal they are signed in
      // or switched to, whereas the client switch needs the list of all OrganisationPrincipals from the db
      clients: OrganisationDtoResolver
    }
  })
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes), FormsModule, SharedCommonUiModule, KENDO_MODULES],
  declarations: [ClientSwitchPageComponent]
})
export class ClientSwitchModule {}
