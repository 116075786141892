import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { TelemetryEnrichmentInitializer } from './telemetry-enrichment-initializer';
import { isPageViewTelemetryItem } from './telemetry-item-predicate';

const getNormalizedRoutePath = (routes: ActivatedRoute[]) =>
  [''].concat(routes.map(r => r.routeConfig?.path).filter((p): p is string => !!p)).join('/');

function getLastRoute(route: ActivatedRoute) {
  let current = route;
  while (current.firstChild) {
    current = current.firstChild;
  }
  return current;
}

@Injectable({ providedIn: 'root' })
export class RouteContextTelemetryInitializer extends TelemetryEnrichmentInitializer {
  constructor(private currentRoute: ActivatedRoute) {
    super();
  }

  protected match = isPageViewTelemetryItem;

  protected getData(_item: ITelemetryItem) {
    const lastRoute = getLastRoute(this.currentRoute);
    const routePath = getNormalizedRoutePath(lastRoute.pathFromRoot) || 'unknown';
    const { params, queryParams } = lastRoute.snapshot;
    const result: { [key: string]: unknown } = {
      routePath
    };
    if (Object.keys(params).length) {
      result.routeParams = params;
    }
    if (Object.keys(queryParams).length) {
      result.routeQueryParams = queryParams;
    }
    return result;
  }
}
