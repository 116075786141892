import { InjectionToken } from '@angular/core';
import { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { CustomProperties, TelemetryInitializer } from './models';

export const TELEMETRY_INITIALIZER = new InjectionToken<TelemetryInitializer>('AppInsights Telemetry Initializer');

/**
 * Convenience base class to create a telemetry initializer that will enrich
 * telementry item with additional data before it's sent to app insights.
 * To register your telemetry initializer use `TELEMETRY_INITIALIZER` as below
 * @example
 * providers: [{
 *   provide: TELEMETRY_INITIALIZER,
 *   useClass: YourTelemetryEnrichmentInitializer,
 *   multi: true
 * }]
 */
export abstract class TelemetryEnrichmentInitializer implements TelemetryInitializer {
  static enrichItem(item: ITelemetryItem, data: CustomProperties) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    item.baseData!.properties = Object.assign(item.baseData!.properties ?? {}, data);
  }

  enrichOrFilter(item: ITelemetryItem): boolean | void {
    if (!this.match(item)) return;

    TelemetryEnrichmentInitializer.enrichItem(item, this.getData(item));
  }

  /**
   * Determine whether telementry item should have it's data enriched
   * @param item telemetry item to test
   */
  protected abstract match(item: ITelemetryItem): boolean;

  /**
   * The additional data that will be added to the telemetry `item`
   * @param item telemetry item to enrich
   */
  protected abstract getData(item: ITelemetryItem): CustomProperties;
}
