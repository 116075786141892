/* eslint-disable @typescript-eslint/no-unsafe-declaration-merging */
import { IConfig, IConfiguration } from '@microsoft/applicationinsights-web';
import { Observable } from 'rxjs';
import { ErrorSelector, TelemetryInitializerFunc, UserSelector } from './models';

export interface AppInsightsConfig<T extends object = object> extends IConfiguration, IConfig {
  /**
   * Enrich telemetry data with the application version.
   *
   * Note: this will add the `appVersionTelemetryInitializer` to `telemetryInitializers` collection
   */
  appVersion?: string;

  /**
   * Start app-insights tracking when the application initializes
   * @defaultValue true
   */
  autoRun?: boolean;

  /**
   * Watch `loginEvents` to enrich telemetry with authenticated user information
   * Defaults to `true` when `userSelector` and `loginEvents` are both supplied,
   * otherwise `false`
   *
   * If set to `true` then `userSelector` and `loginEvents` must also be supplied.
   */
  autoSetAuthenticatedUserContext?: boolean;

  /**
   * Enrich telemetry data with the cloud role name.
   *
   * Application Map in azure monitor uses the cloud role name property to identify
   * the components on the map.
   *
   * Note: this will add the `cloudRoleTelemetryInitializer` to `telemetryInitializers`
   * collection
   */
  cloudRoleName?: string;

  /**
   * If `true` telemetry is collected and sent to app insights. Defaults to `true`
   *
   * This is an alternative to setting `disableTelemetry`. When both `disableTelemetry`
   * and `enableTelemetry` is assigned a value, the value for `disableTelemetry` wins
   */
  enableTelemetry?: boolean;

  /**
   * Enrich Ajax dependency telementry items with the value of the 'content-type' response header.
   * Defaults to `false`
   *
   * @description
   * This configuration parses the content-length response header and adds it the telemetry as a custom
   * property/dimension. In practice however, your server is unlikely to send this header because it
   * will be using chunked encoding to perform content compression.
   *
   * IMPORTANT: This is a convenience setting as an alternative to configuring the following:
   *
   * @example
   * enableResponseHeaderTracking: true,
   * telemetryInitializers: [
   *   contentTypeResponseHeaderTelemetryInitializer,
   *   whitelistResponseHeaderTelemetryInitializer([]),
   * ]
   *
   * @defaultValue false
   */
  enableAjaxResponseLengthTracking?: boolean;

  /**
   * Enrich Page and Ajax dependency telemetry items with information from cloudflare response headers.
   * Defaults to `false`
   *
   * @description
   * Enable to find out more information about how the request was processed by cloudflare. For example whether cloudflare
   * has blocked the request and responded with a challenge instead (a 403 for Ajax requests).
   *
   * IMPORTANT: This is a convenience setting as an alternative to configuring the following:
   *
   * @example
   * enableResponseHeaderTracking: true,
   * telemetryInitializers: [
   *   cloudflareResponseHeaderTelemetryInitializer,
   *   whitelistResponseHeaderTelemetryInitializer([]),
   * ]
   *
   * @defaultValue false
   */
  enableCloudflareResponseTracking?: boolean;

  /**
   * Enrich telemetry data the Network Information API. Defaults to `true`
   * when the navigator.connection object is detected
   * Sub-class `NetworkInfoTelemetryInitializer` to change the enrichment implementation
   */
  enableNetworkInfoTracking?: boolean;

  /**
   * track page views using router change events. Defaults to `true`.
   *
   * This is the preferred way of tracking page views. The alternative is
   * to enable the built-in mechanism from the sdk by setting `enableAutoRouteTracking`
   * to `true`
   * @defaultValue true
   */
  enableRouterChangeTracking?: boolean;

  /**
   * Raise a custom event named 'page-view' in addition to the standard pageEvent telemetry.
   *
   * This can be useful when performing analysis in User Flows or Funnels in the azure
   * app insights portal as these do not (yet) allow to pick an operation name to start
   * the analysis
   *
   * Important: for these events to be created `enableRouterChangeTracking` must be enabled
   * @defaultValue true
   */
  enableCustomPageViewEvent?: boolean;

  /**
   * Enrich telemetry data the current route information. Defaults to `true`.
   *
   * Sub-class `RouteContextTelemetryInitializer` to change the enrichment implementation
   *
   * @defaultValue true
   */
  enableRouterContextTracking?: boolean;

  /**
   * Function to select the error or IExceptionTelemetry to be tracked along with any custom properties
   * Supply this when you want to either:
   * 1. enrich the error or even return a different error than the one that is about to be logged
   * 2. produce an `IExceptionTelemetry` with it's additional telemetry data
   * 3. send custom properties as additional telemetry data
   * 4. prevent the error being logged
   *
   * If the function returns undefined, then the telemety item will not be sent
   */
  errorSelector?: ErrorSelector;

  /**
   * List of telemetry initializers that will be called to either:
   * 1. enrich a telemetry entry with more data
   * 2. prevent the telemetry entry from being published
   *
   * Telemetry initializers will be called one by one with the telemetryItem of type `ITelemetryItem`,
   * in the order they were added, before the telemetry item is pushed for sending.
   * If one of the telemetry initializers returns false or throws an error, then the telemetry item
   * will not be sent.
   *
   * Note: as an alternative to registering a function, you can also create a class that inherits from
   * `TelemetryInitializer` and then register it as a provider as follows:
   *
   * @example
   * providers: [{
   *   provide: TELEMETRY_INITIALIZER,
   *   useClass: YourTelemetryInitializer,
   *   multi: true
   * }]
   */
  telemetryInitializers?: Array<TelemetryInitializerFunc>;

  /**
   * Function that selects user information that will be supplied to the calls
   * to SDK `setAuthenticatedUserContext` method.
   * This function will be called with the values emitted from `loginEvents`
   */
  userSelector?: UserSelector<T>;

  /**
   * An observable that should emit authenticated user information
   */
  loginEvents?: Observable<T>;
}

export class AppInsightsConfig {
  private constructor(json?: Partial<AppInsightsConfig>) {
    if (json) {
      Object.assign(this, json);
    }
  }

  static from<T extends object = object>(json?: Partial<AppInsightsConfig<T>>): AppInsightsConfig<T> {
    return new AppInsightsConfig(json as AppInsightsConfig<object>);
  }
}
