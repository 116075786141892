export enum EntityName {
  Dataset = 'Dataset',
  IdentityPrincipal = 'IdentityPrincipal',
  Group = 'GroupPrincipal',
  Organisation = 'OrganisationPrincipal',
  ReportInfo = 'ReportInfo',
  Report = 'Report',
  Role = 'Role',
  User = 'UserPrincipal',
  UserRole = 'UserRole',
  ReportActivityRecord = 'ReportActivityRecord',
  ReportUserPreference = 'ReportUserPreference',
  ReportAccess = 'ReportAccess',
  GroupMembership = 'GroupMembership',
  PbiReport = 'PbiReport',
  Bookmark = 'Bookmark',
  OrganisationWorkspace = 'OrganisationWorkspace',
  TemplateWorkspace = 'TemplateWorkspace',
  TemplateWorkspaceIcon = 'TemplateWorkspaceIcon',
  OrganisationWorkspaceInfo = 'OrganisationWorkspaceInfo',
  UserReportFilter = 'UserReportFilter'
}
