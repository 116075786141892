<mri-shared-drawer
  *ngIf="vm$ | async as vm"
  headerTitle="Select a different client"
  [isOpen]="true"
  (closeDrawer)="closeDrawer()"
>
  <div *mriSharedDrawerBody class="k-form k-form-inline" role="form">
    <div class="col-lg-12 mri-pt-medium mri-ph-medium">
      <kendo-formfield class="client-switch-search" showErrors="always">
        <kendo-combobox
          role="searchbox"
          [data]="vm.organisation"
          [kendoDropDownFilter]="filteringSettings"
          textField="text"
          valueField="value"
          placeholder="Client ID or Name"
          [loading]="vm.loading"
          [allowCustom]="true"
          (valueChange)="selectItem($event)"
          [valueNormalizer]="valueNormalizer"
          [itemDisabled]="itemDisabled"
          [(ngModel)]="vm.selectedClient"
          [ngClass]="{
            'ng-invalid': vm.selectedClient && !vm.isValid && !vm.loading,
            'ng-valid': vm.selectedClient && vm.isValid && !vm.loading
          }"
        ></kendo-combobox>
        <kendo-formerror *ngIf="vm.validationMessage">{{ vm.validationMessage }}</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>

  <mri-shared-drawer-footer-toolbar
    *mriSharedDrawerFooter
    [canCancel]="true"
    [canSave]="!!vm.selectedClient && vm.isValid && !vm.loading"
    (cancel)="closeDrawer()"
    (save)="switchClient()"
    saveLabel="Select"
    saveIcon="checkmark"
  ></mri-shared-drawer-footer-toolbar>
</mri-shared-drawer>
