import { Directive, OnDestroy, Optional } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { ValidationError } from 'breeze-client';
import { of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BreezeModelSourceDirective } from './breeze-model-source.directive';
import { BreezePropertyDirective } from './breeze-property.directive';
import { fromPropertyValidationErrors } from './from-breeze-event';

@Directive({
  selector: '[ngModel][breezeProperty]:not([breezePropertyValidators])',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: BreezeModelPropertyValidatorDirective,
      multi: true
    }
  ]
})
export class BreezeModelPropertyValidatorDirective implements Validator, OnDestroy {
  private onChange?: () => void;
  private sub = new Subscription();
  private validationErrors: ValidationError[] = [];

  constructor(property: BreezePropertyDirective, @Optional() modelSource?: BreezeModelSourceDirective) {
    if (modelSource?.breezeInstance$) {
      const validationErrors$ = modelSource.breezeInstance$.pipe(
        switchMap(instance => (instance ? fromPropertyValidationErrors(instance, property.name) : of([])))
      );
      this.sub.add(validationErrors$.subscribe(errors => this.setValidationErrors(errors)));
    }
  }

  registerOnValidatorChange?(fn: () => void) {
    this.onChange = fn;
  }

  validate(_: AbstractControl): ValidationErrors | null {
    if (this.validationErrors.length === 0) {
      return null;
    }

    return this.validationErrors.reduce((errs, err) => Object.assign(errs, { [err.key]: err }), {});
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private setValidationErrors(value: ValidationError[]) {
    this.validationErrors = value;
    this.onChange && this.onChange();
  }
}
