import { EntityQuery } from 'breeze-client';
import { Report } from '../../models';

const baseQuery = EntityQuery.from('Reports');
const reportAccess = baseQuery.expand('allowedPrincipals').orderBy(Report.propName('name'));
const reportIconQuery = baseQuery.select([Report.propName('id'), 'organisationWorkspace.templateWorkspace.icon']);

export const reportQueries = {
  selectAll: baseQuery,
  selectReportAccess: reportAccess,
  selectReportIcons: reportIconQuery
};
