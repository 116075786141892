import { EntityQuery, FilterQueryOp } from 'breeze-client';
import { PbiReport } from '../../models';

const baseQuery = EntityQuery.from('PbiReports');

const selectDrafts = baseQuery
  .where(PbiReport.propName('isDraft'), FilterQueryOp.Equals, true)
  .orderBy(PbiReport.propName('createdDate') + ' desc');

export const pbiReportQueries = {
  selectAll: baseQuery,
  selectDrafts
};
