import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutes, isClientOnboarding } from '@mri-platform/dsg/core';

@Component({
  template: ` <h1 class="mri-heading mri-heading--3 mri-mv-medium mri-ml-large">Welcome to {{ title }}!</h1> `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomePageComponent {
  @HostBinding('attr.data-testid') testId = HomePageComponent.name;
  title: string;
  constructor(titleService: Title, router: Router, route: ActivatedRoute) {
    this.title = titleService.getTitle();
    // Navigate to Dashboard page by default
    // Mainly to solve issue caused when switching client which will refresh the whole component
    if (route.snapshot.queryParams[isClientOnboarding]) {
      router.navigate([AppRoutes.ClientOnboardConfig.path]);
    } else {
      router.navigate([AppRoutes.Dashboards.path]);
    }
  }
}
