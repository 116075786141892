import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '@mri-platform/dsg/core';
import { MainMenuItemService } from '@mri-platform/shared/shell';
import { DrawerMenuItem, LinkMenuItem } from '@mri-platform/shared/ui-main-nav-bar';

@Injectable()
export class AppMainMenuItemService implements MainMenuItemService {
  private dashboards: LinkMenuItem = {
    iconName: 'report',
    title: 'Dashboards',
    supportsToggle: true,
    ...AppRoutes.Dashboards.toMenuInfo()
  };

  private dashboardManagement: LinkMenuItem = {
    iconName: 'wrench',
    title: 'Dashboard Management',
    ...AppRoutes.DashboardManagement.toMenuInfo()
  };

  private mriSettings: DrawerMenuItem = {
    iconName: 'gear',
    title: 'Administration',
    links: [
      { text: 'User security settings', ...AppRoutes.UserSettings.toMenuInfo() },
      { text: 'Reconciliation screen', ...AppRoutes.Reconciliation.toMenuInfo() },
      { text: 'Client Onboard Configuration', ...AppRoutes.ClientOnboardConfig.toMenuInfo() }
    ]
  };

  private about: LinkMenuItem = {
    iconName: 'information-circle',
    title: 'About',
    ...AppRoutes.About.toMenuInfo(),
    opener: (_: string) =>
      this.router.navigate(['', { outlets: { modal: [AppRoutes.About.path, 'details'] } }], {
        queryParamsHandling: 'preserve'
      })
  };

  constructor(private router: Router) {}

  get items() {
    return [this.dashboards, this.dashboardManagement, this.mriSettings, this.about];
  }
}
