import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorPolicyService, SanitizedError, muteError } from '@mri-platform/angular-error-handling';
import { OrganisationPrincipal } from '@mri-platform/dsg/entity-state';
import { ClientSwitchService, isNotNullOrUndefined, taggedNamespace } from '@mri-platform/shared/core';
import { AuthService } from '@mri/angular-wfe-proxy-oidc';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { RxState } from '@rx-angular/state';
import { Observable, combineLatest, of } from 'rxjs';
import { map, pluck, shareReplay, switchMap } from 'rxjs/operators';
import { ClientSwitchPageService } from './client-switch-page.service';

interface RouteData {
  clients: OrganisationPrincipal[];
}

interface Organisation {
  text: string;
  value: number;
  tenantKey: string;
  name: string;
  isCurrentClientId: boolean;
}

type ComponentState = RouteData & {
  organisation: Organisation[];
  selectedClient?: Organisation;
  validationMessage: string | null;
  isValid: boolean;
  loading: boolean;
};
type PublicState = ComponentState;

// interface Projections {}

type ViewModel = PublicState; // & Projections;

const initialPublicState: PublicState = {
  organisation: [],
  clients: [],
  validationMessage: null,
  isValid: false,
  loading: false
};

const initialState: ComponentState = {
  ...initialPublicState,
  selectedClient: undefined
};

@Component({
  selector: 'mri-dsg-client-list',
  templateUrl: './client-switch-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class ClientSwitchPageComponent {
  @Output() routeToParent = new EventEmitter();
  @HostBinding('attr.data-testid') testId = ClientSwitchPageComponent.name;
  vm$: Observable<ViewModel>;

  filteringSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private state: RxState<ComponentState>,
    private clientSwitchService: ClientSwitchService,
    private authService: AuthService,
    private errorPolicy: ErrorPolicyService,
    private clientSwitchPageService: ClientSwitchPageService
  ) {
    const tag = taggedNamespace(ClientSwitchPageComponent.name);
    // Set initial state in RxState
    this.state.set(initialState);

    // Connect any observable-driven items to state for items in ComponentState...

    const data$ = this.route.data as Observable<RouteData>;

    const clientValidationMsg$ = this.state.select('selectedClient').pipe(
      isNotNullOrUndefined(),
      switchMap(client => this.validateId(client))
    );
    this.state.connect(clientValidationMsg$, (_, validationMessage) => ({
      isValid: !validationMessage,
      validationMessage,
      loading: false
    }));

    this.state.connect(
      'organisation',
      combineLatest([
        data$.pipe(pluck('clients')),
        this.authService.currentClientId$.pipe(isNotNullOrUndefined())
      ]).pipe(
        map(([data, currentClientId]) => {
          return data.map((x, idx) => {
            return {
              text: x.tenantKey === x.name ? x.tenantKey : `${x.tenantKey} - ${x.name}`,
              value: idx,
              tenantKey: x.tenantKey,
              name: x.name,
              isCurrentClientId: currentClientId === x.tenantKey ? true : false
            };
          });
        })
      )
    );
    // Create projections (calculations from ComponentState)...
    // none

    // Create ViewModel (Projections + PublicState)...

    this.vm$ = this.state.select().pipe(tag('vm'), shareReplay({ refCount: true, bufferSize: 1 }));

    // side effects if any...
    // none
  }

  closeDrawer() {
    return this.router.navigate(['./', { outlets: { drawer: null } }], {
      queryParamsHandling: 'preserve'
    });
  }

  selectItem(selectedClient: Organisation) {
    const loading = !!selectedClient;
    const newSelectedClient = selectedClient
      ? ({
          // The text doesn't change when organisation is present because of the value field
          text: selectedClient.text.toLocaleUpperCase(),
          value: selectedClient.value,
          tenantKey: selectedClient.tenantKey.toLocaleUpperCase(),
          name: selectedClient.name,
          isCurrentClientId: selectedClient.isCurrentClientId
        } as Organisation)
      : selectedClient;
    this.state.set({ selectedClient: newSelectedClient, loading, validationMessage: null });
  }

  switchClient() {
    const selectedClient = this.state.get('selectedClient');
    if (!selectedClient) {
      return;
    }
    const existingOrganisation = this.isOrganisationExists(selectedClient);
    if (!existingOrganisation) {
      const canProceed = confirm('Do you wish to proceed with on-boarding of new client to AIG?');
      if (!canProceed) return;
    }
    this.clientSwitchService.switchClient(
      selectedClient.tenantKey,
      !existingOrganisation ? { isClientOnboarding: true } : undefined
    );
  }

  validateId(client: Organisation) {
    if (client.isCurrentClientId) return of('Select different client ID');
    if (this.isOrganisationExists(client)) return of(null);

    return this.clientSwitchPageService.validateClientId(client.text).pipe(
      this.errorPolicy.catchHandle(muteError),
      map(result => {
        return result instanceof SanitizedError ? result.message : result ? null : 'Invalid Client Id';
      })
    );
  }

  isOrganisationExists(client: Organisation) {
    return this.state
      .get('organisation')
      .find((x: Organisation) => x.tenantKey === client.tenantKey || x.name === client.name);
  }

  valueNormalizer = (text: Observable<string>) =>
    text.pipe(
      map((content: string) => {
        const orgList = this.state.get('organisation');
        const org = orgList.find(
          x => x.tenantKey === content.toLocaleUpperCase() || x.name.toLocaleUpperCase() === content.toLocaleUpperCase()
        );
        if (org) {
          return {
            value: org.value,
            text: org.name === org.tenantKey ? `${org.tenantKey}` : `${org.tenantKey} - ${org.name}`,
            name: org.name,
            tenantKey: org.tenantKey,
            isCurrentClientId: org.isCurrentClientId
          };
        }
        return {
          value: orgList[orgList.length - 1].value + 1,
          text: content,
          name: content,
          tenantKey: content,
          isCurrentClientId: false
        };
      })
    );

  public itemDisabled(itemArgs: { dataItem: Organisation; index: number }) {
    return itemArgs.dataItem.isCurrentClientId;
  }
}
