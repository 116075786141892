import { Type } from '@angular/core';
import { QueryResult, SaveResult } from 'breeze-client';
import { Entity } from 'breeze-client/src/entity-aspect';
import { merge, Observable, Subject } from 'rxjs';
import { filter, mapTo, multicast } from 'rxjs/operators';
import { UnitOfWorkService } from './unit-of-work.service';

export interface BreezeNotificationOptions {
  /**
   * Include the notifications emitted by any unit of work that is a child (grandchild, etc) or no?
   * The default (`false`), is to only include the notifications from the immediate unit of work whose notifications
   * are being requested
   * */
  includeDescendants: boolean;
}

export const defaultNotificationOptions: BreezeNotificationOptions = {
  includeDescendants: false
};

export interface BreezeNotification {
  uow: UnitOfWorkService;
}

export interface FetchSuccessNotification extends BreezeNotification {
  result: QueryResult;
}

export class FetchSuccessNotification {
  constructor({ uow, result }: FetchSuccessNotification) {
    this.result = result;
    this.uow = uow;
  }
}

export interface SaveSuccessNotification extends Pick<SaveResult, 'entities' | 'deletedKeys'>, BreezeNotification {}

export class SaveSuccessNotification {
  constructor({ entities, deletedKeys, uow }: SaveSuccessNotification) {
    this.entities = entities;
    this.deletedKeys = deletedKeys;
    this.uow = uow;
  }

  static from(saveResults: SaveResult, uow: UnitOfWorkService) {
    const { entities, deletedKeys } = saveResults;
    return new SaveSuccessNotification({
      entities,
      deletedKeys,
      uow
    });
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ImportNotification extends BreezeNotification {
  isSandbox: boolean;
}

export class ImportNotification {
  constructor({ isSandbox, uow }: ImportNotification) {
    this.isSandbox = isSandbox;
    this.uow = uow;
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RollbackNotification extends BreezeNotification {}

export class RollbackNotification {
  constructor({ uow }: RollbackNotification) {
    this.uow = uow;
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SaveCancelledNotification extends BreezeNotification {}

export class SaveCancelledNotification {
  constructor({ uow }: SaveCancelledNotification) {
    this.uow = uow;
  }
}

export interface SaveNotification extends BreezeNotification {
  changes: Entity[];
}

export class SaveNotification {
  constructor({ changes, uow }: SaveNotification) {
    this.changes = changes;
    this.uow = uow;
  }
}

export interface SaveErrorNotification extends BreezeNotification {
  error: unknown;
}

export class SaveErrorNotification {
  constructor({ error, uow }: SaveErrorNotification) {
    this.error = error;
    this.uow = uow;
  }
}

export function notificationFor(uow: UnitOfWorkService, options?: Partial<BreezeNotificationOptions>) {
  const { includeDescendants } = options ? { ...defaultNotificationOptions, ...options } : defaultNotificationOptions;
  return (source$: Observable<BreezeNotification>) => {
    if (includeDescendants) {
      return source$.pipe(filter(({ uow: emitter }) => uow.isAncestorOfOrSelf(emitter)));
    } else {
      return source$.pipe(filter(({ uow: emitter }) => emitter === uow));
    }
  };
}

export function notificationOfType<T extends BreezeNotification>(type: Type<T>) {
  return (source$: Observable<BreezeNotification>) => source$.pipe(filter((evt): evt is T => evt instanceof type));
}

export function notificationOfTypes(types: Type<BreezeNotification>[]) {
  return (source$: Observable<BreezeNotification>) => source$.pipe(filter(evt => types.some(t => evt instanceof t)));
}

export interface SaveNotificationFlags {
  cancel: boolean;
  error: boolean;
  start: boolean;
  success: boolean;
}

export function saveNotifications() {
  return (source$: Observable<BreezeNotification>): Observable<SaveNotificationFlags> => {
    const none: SaveNotificationFlags = { start: false, error: false, success: false, cancel: false };
    return source$.pipe(
      multicast(
        () => new Subject<BreezeNotification>(),
        shared$ => {
          const start$ = shared$.pipe(notificationOfType(SaveNotification), mapTo({ ...none, start: true }));
          const error$ = shared$.pipe(notificationOfType(SaveErrorNotification), mapTo({ ...none, error: true }));
          const success$ = shared$.pipe(notificationOfType(SaveSuccessNotification), mapTo({ ...none, success: true }));
          const cancel$ = shared$.pipe(notificationOfType(SaveCancelledNotification), mapTo({ ...none, cancel: true }));
          return merge(start$, error$, success$, cancel$);
        }
      )
    );
  };
}
