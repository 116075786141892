import { Entity } from 'breeze-client';

export function assertIsDefined<T>(val: T): asserts val is NonNullable<T> {
  if (val === undefined || val === null) {
    throw new Error(`Expected 'val' to be defined, but received ${val}`);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isEntity(value: any): value is Entity {
  return value != null && value.entityAspect != null && value.entityType != null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isEntityArray(value: any): value is Array<Entity> {
  if (!Array.isArray(value)) return false;

  // only test the first element for performance reasons
  const [entity] = value;
  return entity === undefined || isEntity(entity);
}
