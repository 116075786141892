import { BaseEntity } from '../base-entity';
import { TemplateWorkspaceIcon } from './template-workspace-icon';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export interface OrganisationWorkspaceInfo {
  id: string;
  isDeleted: boolean;
  templateWorkspaceId: string;
  tenantKey: string;
  workspaceType: string;
  templateWorkspaceIcon: TemplateWorkspaceIcon;
}

/// <module-code> Place module level code between <module-code> tags
export enum WorkspaceType {
  Primary = 'Primary'
}

export type WorkspaceAvatarInfo = Pick<OrganisationWorkspaceInfo, 'workspaceType' | 'templateWorkspaceIcon'>;

export interface OrganisationWorkspaceInfo extends WorkspaceAvatarInfo {
  hasActiveDeployment: boolean;
}
/// </module-code>

export class OrganisationWorkspaceInfo extends BaseEntity {
  /// <code> Place custom code between <code> tags
  static propName(name: keyof OrganisationWorkspaceInfo) {
    return name;
  }

  get workspaceName() {
    if (!this.entityAspect.isNavigationPropertyLoaded(OrganisationWorkspaceInfo.propName('templateWorkspaceIcon'))) {
      return '';
    }
    return `${this.templateWorkspaceIcon.label}${
      this.workspaceType === WorkspaceType.Primary ? '' : ` (${this.workspaceType})`
    }`;
  }
  /// </code>
  // Generated code. Do not place code below this line.
}
