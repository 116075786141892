import { BaseEntity } from '../base-entity';
import { OrganisationPrincipal } from './organisation-principal';
import { UserPrincipal } from './user-principal';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export interface OrganisationMembership {
  userId: string;
  organisationId: string;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  periodEnd: Date;
  periodStart: Date;
  tenantKey: string;
  organisation: OrganisationPrincipal;
  user: UserPrincipal;
}

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class OrganisationMembership extends BaseEntity {
  /// <code> Place custom code between <code> tags
  
  /// </code>
  // Generated code. Do not place code below this line.
}
